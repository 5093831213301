body {
  font-family: 'Inter',"Helvetica", "sans-serif";
}
#header {
  margin-bottom: 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05),
    inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  background-color: #444a65;
}

#content {
  margin: 0 auto;
  width: 85%;
  padding: 10px;
}

body {
  background-color: #ebebeb;
  font-family: 'Inter', sans-serif!important;
}


/* Inter Font override */
/* Avoids having to muck about ThemeProvider nonsense */
#root{
  font-family: 'Inter', sans-serif!important;
}
.MuiPaper-root,
.MuiTypography-root,
.MuiButton-root,
.MuiFormHelperText-root,
.MuiInputBase-root,
.MuiFormLabel-root,
.MuiInputLabel-root,
.MuiButtonBase-root,
.MuiDataGrid-main {
  font-family: 'Inter', sans-serif!important;
}

.tooltip {
  width: 250px;
  white-space: normal;
}

#navMenu {
  display: flex; /* or inline-flex */
  flex-flow: row nowrap; /* is default: columns along the main-axis (row) and no wrapping to next lines */
  align-items: flex-end; /* bottom */
}
.navbar-brand {
  display: inline-block;
}

#MenuLeft {
  float: left;
}
#MenuRight {
  float: right;
}
.menu-bar button {
  color: #f2f2f2;
}

.menu-bar button:hover,
.flat-link button:hover {
  color: #1a5276;
  background-color: #d4e6f1 !important;
}

.menu-bar a {
  vertical-align: bottom;
  display: inline-block;
  color: #f2f2f2;
}
.option-menu {
  width: 20%;
  height: initial;
}

.menu-bar-static-label {
  /*color: #f4f1f1;*/
  color: white;
  font-weight: bold;
}

.username {
  color: white;
}
.username .username-menu {
  line-height: 2.5;
  text-transform: initial;
  margin-right: 2px;
}

.logout button div.MuiLoadingButton-loadingIndicator span{
  color: white;
}

.inline-icon {
  margin-right: 1em;
}

.mymodal {
  position: fixed;
  top: 10;
  left: 0;
  width: 100%;
  height: 80%;
  background: rgb(244, 244, 244, 0.8);
  z-index: 10;
}

.card-width {
  width: 18rem;
}

.modal-organ {
  padding: 1em;
  position: fixed;
  background: rgb(255, 255, 255);
  width: 85%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-main,
.modal-organ {
  padding: 1em;
  position: fixed;
  background: #e6e6e6;
  width: 85%;

  height: 85%;
  top: 50%;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: visible;
  -moz-border-radius: 6px 6px 6px 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px 6px 6px 6px;
  -moz-box-shadow: 3px 3px 5px #535353;
  -webkit-box-shadow: 3px 3px 5px #535353;
  box-shadow: 3px 3px 5px #535353;
}

.modal-main {
  width: 80%;
}

#unityContainer {
  overflow: scroll;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.invisible {
  visibility: hidden;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #212529;
  text-align: left;
  font-size: 1.25rem;
  caption-side: top;
}

.div-border {
  padding: 0.5em;
  margin: 1em 0;
  border-width: 0.2rem;
  border: solid #929699;
  border-radius: 15px;
}

.hr-sect {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  color: rgba(0, 0, 0, 0.35);
  margin: 8px 0px;
}
.hr-sect::before,
.hr-sect::after {
  content: "";
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.35);
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 8px;
}
.form-border {
  border-radius: 5px;
  /*  border-width: 1px;
   border: solid #929699;*/
  background-color: #f2f2f2;
  padding: 20px;
}

.scrollbar-div {
  height: 900px;
  overflow: auto;
}
.short-table {
  /*Temp fix for modal tables that are too tall. 
  Replace with responsive solution next*/
  height: 400px;
}

#SourceIDSearch td,
#SourceIDSearch th {
  text-align: left;
}

.display-block {
  display: block !important;
}

.badge-purple {
  color: #fff;
  background-color: #9933cc;
}
.badge-incomplete {
  background-color: rgb(255, 193, 7);
  color: rgb(33, 37, 41);
}

.nowrap {
  white-space: nowrap;
}

.checkb img {
  padding-left: 20px;
  padding-top: 10px;
}

.no-checkb {
  padding-left: 20px;
}

.add-multi {
  width: 60%;
}

.lab-id-modal .modal-main {
  width: 60%;
}

.locmodal {
  position: fixed;
  top: 40%;
  left: 40%;
  width: 38%;
  height: 33%;
  background: rgb(211, 215, 207);
  z-index: 10;
  overflow: scroll;
}

.modal-wrapper {
  background: white;
  border: 1px solid #d0cccc;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
  margin: 0px 0px 0;
  transition: all 0.8s;
  width: 100%;
  overflow: scroll;
}

.modal-wrapper h3 {
  margin: 30px;
  text-align: center;
}

.modal-body {
  padding: 10px 35px;
  text-align: left;
}

.close-modal-btn {
  color: black;
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin: 0;
}

.close-modal-btn:hover {
  color: black;
}

.btn-cancel,
.btn-continue {
  background: coral;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: bold;
  outline: none;
  padding: 10px;
}

.btn-cancel {
  background-color: #b71c1c;
  float: left;
}

.btn-continue {
  background-color: #1b5e20;
  float: right;
}

.back-drop {
  background-color: rgba(48, 49, 48, 0.42);
  height: 100%;
  position: fixed;
  transition: all 1.3s;
  width: 100%;
}

.open-modal-btn {
  margin: 15px;
  padding: 10px;
  font-weight: bold;
}

/** this allows the Download dialog to appear on top of all lthe dialogs */
.rui {
  z-index: 999999;
}

.rui .footer {
  background-color: greenyellow;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 9999999;
}

.closeScreen {
  float: right;
  font-weight: bold;
  padding: 0 10px;
}

.mod-id,
.mod-check1,
.mod-reg1,
.mod-view2,
.mod-reg2,
.mod-check2,
.mod-view1,
.col-title {
  /**.mod-reg2, .mod-check2, .mod-view1{*/
  font-size: 1em;
  font-weight: bold;
}

.mod-view1 {
  width: 125px;
}

.mod-id {
  width: 350px;
  margin-left: -150px;
}

.mod-reg2 {
  margin-left: 95px;
}

.mod-reg1 {
  margin-left: -60px;
}

.mod-view1 {
  margin-left: 115px;
}
.mod-check1,
.mod-reg1 {
  width: 120px;
}

.mod-check1 {
  margin-left: 0px;
}

.text-left {
  padding-top: 7px;
}

.btn-space {
  margin-right: 5px;
}

.App .navbar-dark .navbar-nav .nav-link {
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
}

.App .bg-primary,
.App .button-primary {
  background-color: #444a65 !important;
}

.container {
  width: 100% !important;
  padding: 1px 0 1px !important;
}

.paper-container {
  width: 100%;
  padding: 20px 20px 20px 20px;
}
.pads {
  padding: 10px 0px 10px 0px;
}

#content {
  padding: 20px 0;
}

.mod-check2 {
  margin-left: 120px;
}

.mod-view2 {
  margin-left: 85px;
}

.errHeader {
  margin-top: 40px;
  text-align: center;
  font-weight: bold;
}

.errBody {
  margin: 30px;
  font-size: 1.2em;
  color: #821a1a;
}

.Collection a,
a:not([href]).ttip {
  color: blue;
}

.colcontainer {
  columns: 2 auto;
}

.dataset {
  margin-left: -7px;
}

.Collection h4 {
  font-weight: bold;
}

/* override bootstrap */
.carousel-item {
  margin-right: 0;
}

/* Hubmap Standard CSS for text */
.table-fmt {
  minwidth: 650;
  width: "100%";
}

.table-hdr-fmt {
  font-size: 0.95rem;
  font-family: Inter Variable, Helvetica, Arial, sans-serif;
  font-weight: bold;
  line-height: 1.5;
}

.portal-jss65 {
  color: #444a65;
}

.portal-jss64 {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.portal-jss74 {
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
}

.portal-jss116 {
  font-size: 0.95rem;
  font-family: "Inter", Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5;
}
.portal-alert-text {
  font-size: 0.7rem;
  font-family: "Inter", Helvetica, Arial, sans-serif;
  font-weight: bold;
  line-height: 1.5;
  color: red;
}

.portal-label {
  font-size: 1.2rem;
  font-family: "Inter", Helvetica, Arial, sans-serif;
  font-weight: bold;
  line-height: 1.5;
}

.portal-jss120 {
  font-size: 2.3rem;
  font-family: "Inter", Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.2;
}

.portal-jss65 {
  color: #444a65;
}

.portal-jss64 {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

/* table row styles */
.portal-jss300 {
  color: rgba(0, 0, 0, 0.87);
}

.portal-jss298 {
  /* display: table-cell;*/
  padding: 16px;
  font-size: 0.8rem;
  text-align: left;
  font-family: Inter Variable, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.43;
  /*border-bottom: 1px solid rgba(224, 224, 224, 1);*/
  vertical-align: inherit;
}

.portal-links {
  text-decoration: none;
  color: rgb(55, 129, 209);
}

ul.no-bullets {
  height: 100px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul {
  /* height: 200px;
  width: 1045px; */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul li {
  margin: 0px 0px 0px 0px;
  padding-left: 0px;
}

div.idlist {
}
div.idlist ul {
  max-width: 1045px;
  width: 100%;
  height: 200px;
  overflow: scroll;
}

div.idlist ul li {
  max-width: 13vmin;
  min-width: 13vmax;
  padding: 8px;
  margin: 2px;
  color: #6eb0fe;
}

div.idlist ul li.button {
  align-self: center;
}

div.idlist ul li:nth-child(odd) {
  /* background-color: #f2f2f2; */
}
div.idlist ul li.active {
  background-color: #6eb0fe;
  color: #fff;
}

a.disabled {
  font-weight: bold;
  pointer-events: none;
  cursor: default;
}

/* class applies to select element itself, not a wrapper element */
.select-css {
  display: block;
  font-size: 14px;
  font-family: sans-serif;
  /*font-weight: 700;*/
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  /*background-color: #fff;*/
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
    for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
    
  */
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 0.65em auto, 100%;
}
/* Hide arrow icon in IE browsers */
.select-css::-ms-expand {
  display: none !important;
}
/* Hover style */
.select-css:hover {
  border-color: #888;
}
/* Focus style */
.select-css:focus {
  border-color: #aaa;
  /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}

/* Set options to normal weight */
.select-css option {
  font-weight: normal;
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir="rtl"] .select-css,
:root:lang(ar) .select-css,
:root:lang(iw) .select-css {
  background-position: left 0.7em top 50%, 0 0;
  padding: 0.6em 0.8em 0.5em 1.4em;
}

/* Disabled styles */
.select-css:disabled,
.select-css[aria-disabled="true"] {
  color: graytext;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
}

.select-css:disabled:hover,
.select-css[aria-disabled="true"] {
  border-color: #aaa;
}

select#groups {
  -webkit-appearance: auto !important;
  appearance: auto !important;
}

.search-filter {
  background-color: white;
}

.portal-jss117 {
  font-size: 0.8rem;
  font-family: Inter Variable, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.66;
}

/* this is to override the DataGrid with pointer cursor */
.MuiDataGrid-root .MuiDataGrid-cell {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-overflow: ellipsis;
  cursor: pointer;
}

.title-error.bg-error {
  color: #fff;
  font-weight: 500;
  background-color: #f44336;
}

.interaction-icon {
  cursor: pointer;
}

tr.row-invalid {
  background: #ff00004d !important;
}
tr.row-invalid:hover {
  border: 1px solid red;
}
tr.row-invalid td {
  border: 1px solid red;
}
table.uploadedStuff-valid {
  background: #68ba99;
}
table tr.row-selection td {
  border-right: 1px solid #cbcbcb;
  padding: 8px;
}
table tr.row-selection td.error {
  background: #ff00004d !important;
}
table tr.tsvData td.titleCase {
  text-transform: capitalize;
}
table tr.tsvData td.wrapping {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 187px;
}
table tr.tsvData.valid-false {
  background: #ff00004d !important;
}
svg.valid {
  color: #beffb6;
}
svg.invalid {
  color: #ff0000;
}

#stepContainer input {
  /* background:red; */
  display: inline !important;
}

/* #stepContainer button{
  display:none;
} */

#BulkMenu div ul,
#IndividualMenu div ul {
  width: auto !important;
  height: auto;
}
/* .bulk-statustext{
  min-height:80px;
} */

.w3-top {
  background-color: #daf0da;
  z-index: 2;
}

#TissueForm .MuiFilledInput-root:first-child {
  background: #e8e8e8;
}

/* To better align the buttons for the Class based forms */
.buttonWrapRight {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: auto;
  margin-top: 10px;
}
.buttonWrapLeft {
  display: flex;
  justify-content: flex-start;
  gap: 0px;
  flex-direction: column;
  margin-bottom: auto;
}

.formSpacer .form-group {
  margin-bottom: 0.5rem;
}

.expanded-form .form-group {
  margin-bottom: 20px;
}
.upload-slide-results div {
  margin-right: 10px;
}
/* Re-apply styles to badges that lost color/formatting between Material UI and MUI */

.badge-info,
.btn-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-neutral,
.btn-neutral {
  background-color: #424242;
  color: white;
}

.badge-success,
.badge-published,
.badge-valid,
.btn-success {
  background-color: #0ecd3a;
  color: white;
}

.badge-hold,
.badge-dark,
.badge-secondary {
  background-color: #424242;
  color: white;
}

.badge-danger {
  background-color: rgb(220, 0, 78);
  color: white;
}

.upload-slide-results {
  padding: 10px;
  margin: 10px;
}

.rowLimitError{
  color:#f44336;
}

.thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.form-select .MuiSelect-icon {
  display: none;
}

.component-error-text {
  color: red !important;
  margin-left: 0px !important;
}

.error-page{
  border: 3px solid #ff0000;
  box-sizing: border-box;
  margin-bottom:20px;
}

.tableRowError{
  background-color: #ff0000!important;
  color: white!important;
}
.metadataHasError{
  border: 1px solid #d32f2f;  
}
.metadataHasError div.rdt_TableRow{
  border-top: 1px solid #d32f2f;  
}
.metadataHasError div.rdt_TableHead div{
  background-color: #d32f2f!important;
  color: white!important;
  font-size:1.1em;
}
.associationTable .MuiDataGrid-footerContainer{
  display:none;
}
.associationTable .MuiDataGrid-columnHeaders{
  background-color: #343a40;
  border-color: #454d55;
  color:#fff;
}
#revert_tooltip{
  background-color: #fff!important;
  color: black!important;
  padding: 5px;
  border-radius: 5px;
  font-size: inherit!important;
}

.title_badge{
  align-items: center;
  color: rgb(42, 111, 184);
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 0.95rem;
  font-weight: 300;
  gap: 4px;
  line-height: 1.5;
}
.fullDialog .MuiPaper-root{
  max-width:1200px!important;
}

.form-control.invalid{
  border: 1px solid red;
}